import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Container,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import api from "../services/api";

const PrayerList = () => {
  const [prayers, setPrayers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [editPrayer, setEditPrayer] = useState(null);
  const [formValues, setFormValues] = useState({
    user_id: 0,
    title: "",
    detail: "",
    type: "",
  });

  useEffect(() => {
    fetchPrayers(currentPage, limit, search);
  }, [currentPage, limit, search]);

  const fetchPrayers = async (page, limit, search) => {
    try {
      const response = await api.post("/prayers/listAll", {
        page: page,
        limit: limit,
        search: search,
      });
      const flattenedPrayers = response.data.data.flatMap((prayerType) =>
        prayerType.data.map((prayer) => ({ ...prayer, type: prayerType.type }))
      );
      setPrayers(flattenedPrayers);
      setTotalPages(Math.ceil(response.data.total / limit));
    } catch (error) {
      console.error("Error fetching prayers", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/prayers/${id}`);
      fetchPrayers(currentPage, limit, search);
    } catch (error) {
      console.error("Error deleting prayer", error);
    }
  };

  const handleOpen = (prayer) => {
    if (prayer) {
      setEditPrayer(prayer);
      setFormValues({
        user_id: 0,
        title: prayer.title,
        detail: prayer.detail,
        type: prayer.type, // Set type when editing
      });
    } else {
      setEditPrayer(null);
      setFormValues({
        user_id: 0,
        title: "",
        detail: "",
        type: "", // Default to empty when creating new
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormValues({
      user_id: 0,
      title: "",
      detail: "",
      type: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (editPrayer) {
      try {
        await api.put(`/prayers/${editPrayer.id}`, formValues);
        fetchPrayers(currentPage, limit, search);
      } catch (error) {
        console.error("Error updating prayer", error);
      }
    } else {
      try {
        const response = await api.post("/prayers", formValues);
        fetchPrayers(currentPage, limit, search);
      } catch (error) {
        console.error("Error creating prayer", error);
      }
    }
    handleClose();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1); // Reset to first page when limit changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            บทสวดมนต์
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          
          <Typography variant="h4" gutterBottom>
            บทสวดมนต์
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpen(null)}
            >
              เพิ่มบทสวดมนต์
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="ค้นหา"
                variant="outlined"
                size="small"
                value={search}
                onChange={handleSearchChange}
                sx={{ mr: 2 }}
              />
              <InputLabel id="limit-label">จำนวนต่อหน้า</InputLabel>
              <Select
                labelId="limit-label"
                value={limit}
                onChange={handleLimitChange}
                size="small"
                sx={{ ml: 2 }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>รหัส</TableCell>
                  <TableCell>ประเภท</TableCell>
                  <TableCell>หัวข้อบทสวด</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prayers.map((prayer) => (
                  <TableRow key={prayer.id}>
                    <TableCell>{prayer.id}</TableCell>
                    <TableCell>{prayer.type}</TableCell>
                    <TableCell>{prayer.title}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpen(prayer)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(prayer.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{editPrayer ? "แก้ไขบทสวด" : "เพิ่มบทสวด"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="title"
              label="หัวข้อบทสวด"
              type="text"
              fullWidth
              value={formValues.title}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="detail"
              label="เนื้อหาบทสวด"
              type="text"
              fullWidth
              multiline
              rows={5}
              value={formValues.detail}
              onChange={handleChange}
            />
            <InputLabel id="type-label" sx={{ mt: 2 }}>
              ประเภท
            </InputLabel>
            <Select
              labelId="type-label"
              name="type"
              value={formValues.type}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="ศาสนาฮินดู">ศาสนาฮินดู</MenuItem>
              <MenuItem value="พุทธศาสนา">พุทธศาสนา</MenuItem>
              <MenuItem value="พุทธจีน">พุทธจีน</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ยกเลิก
            </Button>
            <Button onClick={handleSave} color="primary">
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default PrayerList;
