import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";

import {
  Map,
  Marker,
  Pin,
} from "@vis.gl/react-google-maps";

import api from "../services/api";

const INITIAL_CAMERA = {
  center: { lat: 13.75547535254692, lng: 100.49953418649267 },
  zoom: 13,
};

const WishList = () => {
  const [wishes, setWishes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [editWish, setEditWish] = useState(null);
  const [formValues, setFormValues] = useState({
    title: "",
    detail: "",
    lati: "",
    longti: "",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 13.75547535254692,
    lng: 100.49953418649267,
  });
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const mapRef = useRef(null);

  useEffect(() => {
    fetchWishes(currentPage, limit, searchTerm);
  }, [currentPage, limit, searchTerm]);

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      lati: selectedLocation.lat,
      longti: selectedLocation.lng,
    }));
  }, [selectedLocation]);

  const fetchWishes = async (page, limit, search) => {
    try {
      const response = await api.post("/wishs/listAll", {
        page,
        limit,
        search,
      });
      setWishes(response.data.data);
      setTotalPages(Math.ceil(response.data.total / limit));
    } catch (error) {
      console.error("Error fetching wishes", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/wishs/${id}`);
      fetchWishes(currentPage, limit, searchTerm);
    } catch (error) {
      console.error("Error deleting wish", error);
    }
  };

  const handleOpen = (wish) => {
    if (wish) {
      setEditWish(wish);
      setFormValues({
        title: wish.title,
        detail: wish.detail,
        lati: wish.lati,
        longti: wish.longti,
      });
      setSelectedLocation({
        lat: parseFloat(wish.lati),
        lng: parseFloat(wish.longti),
      });
    } else {
      setEditWish(null);
      setFormValues({
        title: "",
        detail: "",
        lati: "",
        longti: "",
      });
      setSelectedLocation(INITIAL_CAMERA.center);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormValues({
      title: "",
      detail: "",
      lati: "",
      longti: "",
    });
    setSelectedLocation(INITIAL_CAMERA.center);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    const updatedValues = {
      ...formValues,
      lati: selectedLocation.lat,
      longti: selectedLocation.lng,
      user_id: 0,
    };
    try {
      if (editWish) {
        await api.put(`/wishs/${editWish.id}`, updatedValues);
        fetchWishes(currentPage, limit, searchTerm);
      } else {
        const response = await api.post("/wishs", updatedValues);
        fetchWishes(currentPage, limit, searchTerm);
      }
      handleClose();
    } catch (error) {
      console.error("Error saving wish", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
  };

  const handleMapDragEnd = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setSelectedLocation({
        lat: center.lat(),
        lng: center.lng(),
      });
    }
  };

  const handleMapClick = (event) => {
    const { detail } = event;
    console.log(detail.latLng);
    setSelectedLocation(detail.latLng);
  };

  return (
    <Box sx={{ display: "flex" }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
        บทอธิษฐาน
        </Typography>
      </Toolbar>
    </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      > 
        <Typography variant="h4" gutterBottom>
          บทอธิษฐาน
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(null)}
          >
            เพิ่มบทอธิษฐาน
          </Button>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="ค้นหา"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ mr: 2 }}
            />
            <InputLabel id="limit-label">จำนวนต่อหน้า</InputLabel>
            <Select
              labelId="limit-label"
              value={limit}
              onChange={handleLimitChange}
              size="small"
              sx={{ ml: 2 }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>รหัส</TableCell>
                <TableCell>วันที่</TableCell>
                <TableCell>หัวข้อบทอธิษฐาน</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wishes.map((wish) => (
                <TableRow key={wish.id}>
                  <TableCell>{wish.id}</TableCell>
                  <TableCell>{wish.created_at}</TableCell>
                  <TableCell>{wish.title}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpen(wish)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(wish.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{ mt: 2, justifyContent: "center", display: "flex" }}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          {editWish ? "แก้ไขบทอธิษฐาน" : "เพิ่มบทอธิษฐาน"}
        </DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label="หัวข้อ"
            variant="outlined"
            value={formValues.title}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            name="detail"
            label="รายละเอียด"
            variant="outlined"
            value={formValues.detail}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            sx={{ mt: 2 }}
          />
          <Typography variant="h6" sx={{ mt: 3 }}>
            ตำแหน่งปัจจุบัน: {selectedLocation.lat}, {selectedLocation.lng}
          </Typography>
          <Box sx={{ height: 400, mt: 2 }}>
            <Map
              ref={mapRef}
              initialProps={cameraProps}
              onClick={handleMapClick}
              onDragEnd={handleMapDragEnd}
              onLoad={handleMapLoad}
              style={{ width: "100%", height: "100%" }}
              defaultZoom={13}
              defaultCenter={selectedLocation}
              gestureHandling={"greedy"}
            >
              <Marker position={selectedLocation}>
                <Pin scale={1.5} />
              </Marker>
            </Map>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            ยกเลิก
          </Button>
          <Button onClick={handleSave} color="primary">
            บันทึก
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WishList;
