import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Container,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import api from "../services/api";

// Colors for Pie Chart
const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const months = [
  { name: "มกราคม", value: "1" },
  { name: "กุมภาพันธ์", value: "2" },
  { name: "มีนาคม", value: "3" },
  { name: "เมษายน", value: "4" },
  { name: "พฤษภาคม", value: "5" },
  { name: "มิถุนายน", value: "6" },
  { name: "กรกฎาคม", value: "7" },
  { name: "สิงหาคม", value: "8" },
  { name: "กันยายน", value: "9" },
  { name: "ตุลาคม", value: "10" },
  { name: "พฤศจิกายน", value: "11" },
  { name: "ธันวาคม", value: "12" }
];

// Adjust currentMonth to 1-based index
const currentMonth = (new Date().getMonth() + 1).toString(); 

const currentYear = new Date().getFullYear() + 543;
const years = Array.from({ length: 6 }, (_, i) => currentYear - i);

const Dashboard = () => {
  const [tabValue, setTabValue] = useState("1");
  const [yearData, setYearData] = useState({});
  const [monthData, setMonthData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(currentMonth); // Default to current month
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  useEffect(() => {
    // Fetch year data
    api
      .get(`/overall/yearAll/${selectedYear}`)
      .then((response) => {
        setYearData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching year data", error);
      });

    // Fetch month data
    api
      .get(`/overall/monthAll/${selectedMonth}/${selectedYear}`)
      .then((response) => {
        setMonthData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching month data", error);
      });
  }, [selectedMonth, selectedYear]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const formatDataForLineChart = (data) => {
    return Object.entries(data).map(([key, value]) => ({
      name: key,
      uv: value,
    }));
  };

  const pieData = [
    {
      name: "Hindu",
      value: parseFloat(yearData.typePercentagesYear?.hindu || 0),
    },
    {
      name: "Taoist",
      value: parseFloat(yearData.typePercentagesYear?.taoist || 0),
    },
    {
      name: "Christian",
      value: parseFloat(yearData.typePercentagesYear?.christian || 0),
    },
    {
      name: "Buddhism",
      value: parseFloat(yearData.typePercentagesYear?.buddhism || 0),
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="เดือน" value="1" />
              <Tab label="ปี" value="2" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                  <Typography variant="h6">
                    {months.find(m => m.value === selectedMonth)?.name} {selectedYear}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>เลือกเดือน</InputLabel>
                  <Select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    label="เลือกเดือน"
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>เลือกปี</InputLabel>
                  <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    label="เลือกปี"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">ข้อมูลรายเดือน</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={formatDataForLineChart(monthData.dailySums || {})}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">การแจกแจงค่าใช้จ่าย</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {pieData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">การแจกแจงตามหมวดหมู่</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={formatDataForLineChart(monthData.dailySums || {})}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="uv" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                  <Typography variant="h6">
                    {selectedYear}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>เลือกปี</InputLabel>
                  <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    label="เลือกปี"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">ข้อมูลรายปี</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={formatDataForLineChart(yearData.monthlySums || {})}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>
    </Box>
  );
};

export default Dashboard;
