import React from "react";

import buddhismActive from "../assets/IMU_buddhism_symbol_active.png";
import buddhismInactive from "../assets/IMU_buddhism_symbol.png";
import taoistActive from "../assets/IMU_taoist_icon_active.png";
import taoistInactive from "../assets/IMU_taoist_symbol.png";
import hinduActive from "../assets/IMU_Hindu_symbol_active.png";
import hinduInactive from "../assets/IMU_Hindu_symbol.png";
import christianActive from "../assets/IMU_christain_symbol_active.png";
import christianInactive from "../assets/IMU_christain_symbol.png";

const ImageSelector = ({ selectedValue, onChange }) => {
  const handleSelection = (type) => {
    onChange(type);
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          onClick={() => handleSelection("buddhism")}
          style={{ border: "1px solid transparent", cursor: "pointer" }}
        >
          <img
            src={
              selectedValue === "buddhism" ? buddhismActive : buddhismInactive
            }
            alt="Buddhism"
            style={{ width: "80px", height: "80px" }}
          />
        </div>
        <div
          onClick={() => handleSelection("taoist")}
          style={{ border: "1px solid transparent", cursor: "pointer" }}
        >
          <img
            src={selectedValue === "taoist" ? taoistActive : taoistInactive}
            alt="Taoist"
            style={{ width: "80px" }}
          />
        </div>
        <div
          onClick={() => handleSelection("hindu")}
          style={{ border: "1px solid transparent", cursor: "pointer" }}
        >
          <img
            src={selectedValue === "hindu" ? hinduActive : hinduInactive}
            alt="Hindu"
            style={{ width: "80px" }}
          />
        </div>
        <div
          onClick={() => handleSelection("christain")}
          style={{ border: "1px solid transparent", cursor: "pointer" }}
        >
          <img
            src={
              selectedValue === "christain"
                ? christianActive
                : christianInactive
            }
            alt="Christain"
            style={{ width: "80px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageSelector;
