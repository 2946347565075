import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
  Password as PasswordIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../services/api";

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [formValues, setFormValues] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    birthDate: "",
    password: "",
    confirmPassword: "",
    gender: "",
  });
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (editUser) {
      const formattedBirthDate = moment(editUser.birthDate).format(
        "YYYY-MM-DD"
      );
      setFormValues({
        id: editUser.id,
        firstName: editUser.firstName,
        lastName: editUser.lastName,
        email: editUser.email,
        phoneNumber: editUser.phoneNumber,
        birthDate: formattedBirthDate,
        password: editUser.password,
        confirmPassword: editUser.password,
        gender: editUser.gender,
      });
    } else {
      setFormValues({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        birthDate: "",
        password: "",
        confirmPassword: "",
        gender: "",
      });
    }
  }, [editUser]);

  const fetchUsers = async () => {
    try {
      const response = await api.get("/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  const handleClickOpen = (user) => {
    setEditUser(user);
    setOpen(true);
  };

  const handleChangePassword = (user) => {
    setEditUser(user);
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setOpenChangePassword(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenChangePassword(false);
    setEditUser(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") {
      setOldPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const handleSave = async () => {
    if (formValues.password !== formValues.confirmPassword) {
      alert("รหัสผ่านไม่ตรงกัน!");
      return;
    }

    try {
      if (editUser) {
        await api.put(`/users/${editUser.id}`, formValues);
      } else {
        await api.post("/users", formValues);
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error("Error saving user", error);
    }
  };

  const handlePasswordSave = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("รหัสผ่านไม่ตรงกัน!");
      return;
    }

    try {
      if (editUser) {
        await api.post(`/users/passwordChange/${editUser.id}`, {
          password: oldPassword,
          newPassword,
          confirmNewPassword,
        });
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error("Error changing password", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/users/${id}`);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };

  const handleView = (user) => {
    navigate(`/viewList/${user.id}`, { state: { user } });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            ผู้ใช้งาน
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Typography variant="h4" gutterBottom>
          ผู้ใช้งาน
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen(null)}
        >
          เพิ่มผู้ใช้
        </Button>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>รหัส</TableCell>
                <TableCell>ชื่อ</TableCell>
                <TableCell>นามสกุล</TableCell>
                <TableCell>อีเมล์</TableCell>
                <TableCell>เบอร์โทรศัพท์</TableCell>
                <TableCell>วันเกิด</TableCell>
                <TableCell>เพศ</TableCell>
                <TableCell>การกระทำ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                  <TableCell>
                    {moment(user.birthDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{user.gender}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleClickOpen(user)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleChangePassword(user)}
                    >
                      <PasswordIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(user.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="default"
                      onClick={() => handleView(user)}
                    >
                      <ViewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openChangePassword} onClose={handleClose}>
          <DialogTitle>เปลี่ยนรหัสผ่าน</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="oldPassword"
              label="รหัสผ่านเก่า"
              type="password"
              fullWidth
              value={oldPassword}
              onChange={handlePasswordChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="newPassword"
              label="รหัสผ่านใหม่"
              type="password"
              fullWidth
              value={newPassword}
              onChange={handlePasswordChange}
            />
            <TextField
              margin="dense"
              name="confirmNewPassword"
              label="ยืนยันรหัสผ่านใหม่"
              type="password"
              fullWidth
              value={confirmNewPassword}
              onChange={handlePasswordChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ยกเลิก
            </Button>
            <Button onClick={handlePasswordSave} color="primary">
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {editUser ? "แก้ไขข้อมูลผู้ใช้" : "กรอกข้อมูลผู้ใช้ใหม่"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editUser
                ? "กรอกข้อมูลเพื่อแก้ไขข้อมูลผู้ใช้"
                : "กรอกข้อมูลผู้ใช้ใหม่"}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="firstName"
              label="ชื่อ"
              type="text"
              fullWidth
              value={formValues.firstName}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="lastName"
              label="นามสกุล"
              type="text"
              fullWidth
              value={formValues.lastName}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="email"
              label="อีเมล์"
              type="email"
              fullWidth
              value={formValues.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="phoneNumber"
              label="เบอร์โทรศัพท์"
              type="tel"
              fullWidth
              value={formValues.phoneNumber}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="birthDate"
              label="วันเกิด"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formValues.birthDate}
              onChange={handleChange}
            />
            <FormControl component="fieldset">
              <RadioGroup
                name="gender"
                value={formValues.gender}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="ชาย"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="หญิง"
                />
              </RadioGroup>
            </FormControl>
            {!editUser && (
              <>
                <TextField
                  margin="dense"
                  name="password"
                  label="รหัสผ่าน"
                  type="password"
                  fullWidth
                  value={formValues.password}
                  onChange={handleChange}
                />
                <TextField
                  margin="dense"
                  name="confirmPassword"
                  label="ยืนยันรหัสผ่าน"
                  type="password"
                  fullWidth
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ยกเลิก
            </Button>
            <Button onClick={handleSave} color="primary">
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Users;
