import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Container,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import api from "../services/api";
import moment from "moment";

const ImportantDayList = () => {
  const [importantDays, setImportantDays] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [editImportantDay, setEditImportantDay] = useState(null);
  const [formValues, setFormValues] = useState({
    date: "",
    title: "",
    detail: "",
  });

  useEffect(() => {
    fetchImportantDays(currentPage, limit, search);
  }, [currentPage, limit, search]);

  const fetchImportantDays = async (page, limit, search) => {
    try {
      const response = await api.post("/importantDays/listAdmin", {
        page: page,
        limit: limit,
        search: search,
      });
      const flattenedImportantDays = response.data.data.data;
      setImportantDays(flattenedImportantDays);
      setTotalPages(Math.ceil(response.data.data.total / limit));
    } catch (error) {
      console.error("Error fetching important days", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/importantDays/${id}`);
      fetchImportantDays(currentPage, limit, search);
    } catch (error) {
      console.error("Error deleting important day", error);
    }
  };

  const handleOpen = (importantDay) => {
    if (importantDay) {
      setEditImportantDay(importantDay);
      setFormValues({
        date: importantDay.date
          ? moment(importantDay.date).format("YYYY-MM-DD")
          : "",
        title: importantDay.title,
        detail: importantDay.detail,
      });
    } else {
      setEditImportantDay(null);
      setFormValues({
        date: "",
        title: "",
        detail: "",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormValues({
      date: "",
      title: "",
      detail: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (editImportantDay) {
      try {
        await api.put(`/importantDays/${editImportantDay.id}`, formValues);
        fetchImportantDays(currentPage, limit, search);
      } catch (error) {
        console.error("Error updating important day", error);
      }
    } else {
      try {
        await api.post("/importantDays", formValues);
        fetchImportantDays(currentPage, limit, search);
      } catch (error) {
        console.error("Error creating important day", error);
      }
    }
    handleClose();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            วันสำคัญ
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Typography variant="h4" gutterBottom>
            วันสำคัญ
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpen(null)}
            >
              เพิ่มวันสำคัญ
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="ค้นหา"
                variant="outlined"
                size="small"
                value={search}
                onChange={handleSearchChange}
                sx={{ mr: 2 }}
              />
              <InputLabel id="limit-label">จำนวนต่อหน้า</InputLabel>
              <Select
                labelId="limit-label"
                value={limit}
                onChange={handleLimitChange}
                size="small"
                sx={{ ml: 2 }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>วันที่</TableCell>
                  <TableCell>วันสำคัญ</TableCell>
                  <TableCell>รายละเอียด</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {importantDays.map((importantDayItem) => (
                  <TableRow key={importantDayItem.id}>
                    <TableCell>
                      {importantDayItem.date
                        ? moment(importantDayItem.date).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>{importantDayItem.title}</TableCell>
                    <TableCell>
                      {importantDayItem.detail ? importantDayItem.detail : "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpen(importantDayItem)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(importantDayItem.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {editImportantDay ? "แก้ไขวันสำคัญ" : "เพิ่มวันสำคัญ"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="date"
              label="วันที่ (YYYY-MM-DD)"
              type="date"
              fullWidth
              value={formValues.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="dense"
              name="title"
              label="วันสำคัญ"
              type="text"
              fullWidth
              value={formValues.title}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="detail"
              label="รายละเอียด"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={formValues.detail}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ยกเลิก
            </Button>
            <Button onClick={handleSave} color="primary">
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default ImportantDayList;
