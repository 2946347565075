import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import { APIProvider } from "@vis.gl/react-google-maps";

import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import UsersAdmin from "./pages/UsersAdmin";
import Login from "./pages/Login";
import ViewList from "./pages/ViewList";
import PrayerList from "./pages/PrayerList";
import WishList from "./pages/WishList";
import ImportantDays from "./pages/ImportantDays";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <APIProvider apiKey="AIzaSyCmikpi0sk2eOolU1mEvzvaoF803SqBEAk" libraries={['marker']}>
          <CssBaseline />
          <Sidebar />
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <Toolbar />
            <Routes>
              <Route
                path="/"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/usersAdmin"
                element={
                  <ProtectedRoute>
                    <UsersAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/viewList/:user_id"
                element={
                  <ProtectedRoute>
                    <ViewList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/prayers"
                element={
                  <ProtectedRoute>
                    <PrayerList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/wishes"
                element={
                  <ProtectedRoute>
                    <WishList />
                  </ProtectedRoute>
                }
              />
                <Route
                path="/importantDays"
                element={
                  <ProtectedRoute>
                    <ImportantDays />
                  </ProtectedRoute>
                }
              />
              <Route path="/access-denied" element={<AccessDenied />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </APIProvider>
      </Box>
    </Router>
  );
}

function AccessDenied() {
  return <div>Access Denied!</div>;
}

function NotFound() {
  return <div>Not Found!</div>;
}

export default App;
