import React from "react";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";

// Importing custom icons
import DashboardIcon from "../assets/IMU_blk_icon.png";
import UsersIcon from "../assets/IMU_Male_active.png";
import PrayersIcon from "../assets/IMU_pray_icon.png";
import WishesIcon from "../assets/IMU_horo_icon.png";
import LogoutIcon from "@mui/icons-material/Logout";
import MeetingRoomIcon from '../assets/IMU_add button.png';
const drawerWidth = 240;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem("accessToken"); // Check for token in localStorage

  const handleLogout = () => {
    localStorage.removeItem("accessToken"); // Remove authentication data
    navigate("/login"); // Redirect to login page
  };

  if (!isAuthenticated) {
    return null; // Do not render Sidebar if not authenticated
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {[
            { text: "Dashboard", to: "/dashboard", icon: DashboardIcon },
            { text: "ผู้ใช้งาน", to: "/users", icon: UsersIcon },
            { text: "ผู้ดูแลระบบ", to: "/usersAdmin", icon: UsersIcon },
            { text: "บทสวดมนต์", to: "/prayers", icon: PrayersIcon },
            { text: "บทอธิษฐาน", to: "/wishes", icon: WishesIcon },
            { text: "วันสำคัญ", to: "/importantDays", icon: MeetingRoomIcon },
          ].map((item) => (
            <ListItem
              button
              component={Link}
              to={item.to}
              key={item.text}
              selected={location.pathname === item.to}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#D8D6FF",
                  "& .MuiListItemIcon-root": {
                    color: "#000",
                  },
                },
              }}
            >
              <ListItemIcon>
                <img src={item.icon} alt={item.text} style={{ width: 26, height: 26 }} />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
